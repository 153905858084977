import * as React from 'react';
import { Button } from '@mui/material';
import { PresetOutputWithRelations, UserRole, Warehouse } from '@schibsted-distribution/parcel-registration-api-client';
import { SimpleNotification } from '../../components/SimpleNotification';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useAddPreset, useGetPresets, useUpdatePreset } from '../../hooks/presets';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { GlobalContext } from '../../globalContext';
import { useGetWarehouses } from '../../hooks/externalRegisterApi';
import { getDefaultPresetWorkstations } from '../../lib/getDefaultPresetWorkstations';

type importExportButtonsTypes = {
  data: PresetOutputWithRelations[];
  selectionModel: GridSelectionModel;
};

export const ImportExportButtons = (props: importExportButtonsTypes) => {
  const fileReader = new FileReader();
  const router = useRouter();
  const { data: presetsData } = useGetPresets();
  const { data: warehousesData } = useGetWarehouses();
  const { whoAmIData } = useContext(GlobalContext);
  const [isExportError, setIsExportError] = React.useState(false);
  const [isImportError, setIsImportError] = React.useState(false);
  const { data, selectionModel } = props;
  const { mutateAsync: updatePreset, isSuccess: isUpdateSuccess, isError: isUpdateError } = useUpdatePreset();
  const { mutate: addPreset, isSuccess: isAddPresetSuccess, isError: isAddPresetError } = useAddPreset();
  const isNotificationErrorVisible = isExportError || isImportError || isUpdateError || isAddPresetError;
  const isNotificationSuccessVisible = isUpdateSuccess || isAddPresetSuccess;

  const exportBlob = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    });
  };

  const exportPresets = () => {
    if (selectionModel.length === 0) {
      setIsExportError(true);
    } else {
      const selectedPresets = data ? data.filter(preset => selectionModel.includes(preset.id)) : [];
      const jsonSelectedPresets = JSON.stringify(selectedPresets);
      const blob = new Blob([jsonSelectedPresets], {
        type: 'text/json'
      });
      exportBlob(blob, `exported-presets-${Date.now()}.json`);
    }
  };

  const isPresetValid = (preset: PresetOutputWithRelations, presetsData: PresetOutputWithRelations[]) => {
    const requiredFields = [
      'content',
      'name',
      'alias',
      'labelHeader',
      'code',
      'comment',
      'cargoType',
      'isParent',
      'workStations'
    ];
    const doRequiredFieldsExist = requiredFields.every(field => field in preset);
    const isCodeUnique = !presetsData.some(singlePreset => singlePreset.code === preset.code);
    const isAllowed = preset.isParent ? whoAmIData.role === UserRole.ADMIN : true;
    return doRequiredFieldsExist && isCodeUnique && isAllowed;
  };

  const importPreset = (
    preset: PresetOutputWithRelations,
    presetsData: PresetOutputWithRelations[],
    warehousesData: Warehouse[]
  ) => {
    if (isPresetValid(preset, presetsData)) {
      const completedPreset = {
        ...preset,
        workStationsNames: getDefaultPresetWorkstations(whoAmIData, warehousesData, preset.workStations),
        labelHeader: preset.labelHeader || ''
      };
      const doesPresetExists = presetsData.some(singlePreset => singlePreset.id === preset.id);
      if (doesPresetExists) {
        updatePreset(
          { id: preset.id, value: completedPreset },
          {
            onSuccess: () => router.reload(),
            onError: () => setIsImportError(true)
          }
        );
      } else {
        const { id, ...newPresetData } = completedPreset;
        addPreset(newPresetData, {
          onSuccess: () => router.reload(),
          onError: () => setIsImportError(true)
        });
      }
    } else {
      setIsImportError(true);
      return;
    }
  };

  const handleImportedPresets = (importedPresets: PresetOutputWithRelations[]) => {
    if (!presetsData || !warehousesData) {
      setIsImportError(true);
      return;
    }
    importedPresets.map(importedPreset => {
      importPreset(importedPreset, presetsData, warehousesData);
    });
  };

  const importPresets = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.target || !e.target.files || !e.target.files[0]) return;
    const file = e.target.files[0];
    if (file) {
      fileReader.readAsText(file);
      fileReader.onload = function () {
        const jsonOutput = fileReader.result;
        const object = JSON.parse(jsonOutput as string);
        handleImportedPresets(object);
      };
      fileReader.onerror = function () {
        setIsImportError(true);
      };
    }
    e.target.value = '';
  };

  const getNotificationMessage = () => {
    if (isExportError) {
      return 'No presets selected to export.';
    } else if (isImportError) {
      return 'Import failed. Remember to provide unique id and code. Also you cannot import Master Preset as Local Admin!';
    } else if (isUpdateError) {
      return 'Preset update failed.';
    } else if (isAddPresetError) {
      return 'Adding new preset failed.';
    } else if (isUpdateSuccess) {
      return 'Preset update succeed.';
    } else if (isAddPresetSuccess) {
      return 'Adding new preset succeed.';
    } else {
      return '';
    }
  };

  return presetsData && whoAmIData ? (
    <>
      {warehousesData && (
        <Button>
          <label htmlFor='file-upload'>IMPORT</label>
          <input type='file' id='file-upload' accept='.json' onChange={e => importPresets(e)} style={{ display: 'none' }} />
        </Button>
      )}
      <Button onClick={() => exportPresets()}>EXPORT</Button>
      <SimpleNotification
        isError={isNotificationErrorVisible}
        isSuccess={isNotificationSuccessVisible}
        errorMessage={getNotificationMessage()}
      />
    </>
  ) : null;
};
