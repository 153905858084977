import { useMutation, useQuery, useQueryClient } from 'react-query';
import getConfig from 'next/config';
import { Address, ParcelRegistrationAPIClient } from '@schibsted-distribution/parcel-registration-api-client';

const { publicRuntimeConfig } = getConfig();

const { API_URL } = publicRuntimeConfig;
const apiClient = new ParcelRegistrationAPIClient({ baseURL: API_URL });

declare enum ENDPOINTS {
  CARGO_TYPES = 'cargoTypes',
  ADDRESSES = 'addresses',
  WAREHOUSES = 'warehouses',
  EXTERNAL = 'external'
}

const queryOptions = {
  refetchOnWindowFocus: false
};

export const useGetCargoTypes = () => useQuery(ENDPOINTS.CARGO_TYPES, () => apiClient.getCargoTypes(), queryOptions);

export const useGetAddresses = () => useQuery(ENDPOINTS.ADDRESSES, () => apiClient.getAddresses(), queryOptions);

export const useGetWarehouses = () => useQuery(ENDPOINTS.WAREHOUSES, () => apiClient.getWarehouses(), queryOptions);

export const useGetExternal = (url: string) => {
  return useQuery([ENDPOINTS.EXTERNAL, url], () => apiClient.getCollectionFromExternalResource(url), {
    ...queryOptions,
    enabled: !!url
  });
};

export const useAddAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(({ address }: { address: Address }) => apiClient.addAddress(address), {
    onSuccess: data => {
      queryClient.setQueryData([ENDPOINTS.ADDRESSES, data.name], data);
    }
  });
};
