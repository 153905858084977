import { useMutation, useQuery, useQueryClient } from 'react-query';
import getConfig from 'next/config';
import {
  AddPresetParams,
  ParcelRegistrationAPIClient,
  Preset,
  UpdatePresetParams
} from '@schibsted-distribution/parcel-registration-api-client';
import { AxiosError } from 'axios';
import Router from 'next/router';

const { publicRuntimeConfig } = getConfig();

const { API_URL } = publicRuntimeConfig;
const apiClient = new ParcelRegistrationAPIClient({ baseURL: API_URL });
const PRESET_QUERY_KEY = 'presets';

export const useGetPresets = () => useQuery(PRESET_QUERY_KEY, () => apiClient.getAllPresets());

export const useGetPresetById = (id: string) =>
  useQuery(['preset', id], () => apiClient.getPresetById(id), {
    enabled: !!id,
    onError: (data: AxiosError) => {
      if (data?.response?.status === 404) Router.push('/404');
    }
  });

export const useDeletePreset = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (value: Preset['id']) => {
      return apiClient.removePreset(value);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PRESET_QUERY_KEY);
      }
    }
  );
};

export const useUpdatePreset = () => {
  const queryClient = useQueryClient();
  let addresses: string[];

  return useMutation(
    ({ id, value }: { id: string; value: UpdatePresetParams }) => {
      addresses = value.presetAddresses;
      return apiClient.changePreset(id, value);
    },
    {
      onSuccess: data => {
        apiClient.addAddressesPresetAssociation({ addressesIds: addresses, presetIds: data.id }).finally(() => {
          queryClient.setQueryData(['preset', data.id], { ...data, presetAddresses: addresses });
        });
      }
    }
  );
};

export const useAddPreset = () =>
  useMutation((value: AddPresetParams) => {
    return apiClient.addPreset(value);
  });
