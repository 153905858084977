import * as React from 'react';
import { Alert, Snackbar } from '@mui/material';

interface SimpleInfoNotificationProps {
  isError: boolean;
  isSuccess: boolean;
  errorMessage?: string | null;
}

export const SimpleNotification = ({
  isError,
  isSuccess,
  errorMessage = 'Error making API request'
}: SimpleInfoNotificationProps) => {
  const [isOpen, setIsOpen] = React.useState(isError || isSuccess);

  React.useEffect(() => {
    setIsOpen(isError || isSuccess);
  }, [isError, isSuccess]);

  return (
    <Snackbar
      open={isOpen}
      onClose={() => setIsOpen(false)}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div>
        {isSuccess && (
          <Alert severity='success' sx={{ width: '100%' }}>
            Success!
          </Alert>
        )}
        {isError && (
          <Alert severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        )}
      </div>
    </Snackbar>
  );
};
