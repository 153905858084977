import { MainLayout } from '../../layouts/MainLayout';
import * as React from 'react';
import { GridColDef, GridRowId, GridSelectionModel, GridToolbarContainer } from '@mui/x-data-grid';
import { useAddPreset, useDeletePreset, useGetPresets } from '../../hooks/presets';
import { Button, Grid, IconButton } from '@mui/material';
import { Delete, Edit, Add, QrCode2, ContentCopy, LocalPolice } from '@mui/icons-material';
import { DeletePresetDialog } from '../../components/DeletePresetDialog';
import { NonSelectableGrid } from '../../components/Styled/NonSelectableGrid';
import NextLink from 'next/link';
import { format } from 'date-fns';
import {
  AddPresetParams,
  PresetOutputWithRelations,
  UserRole,
  Warehouse
} from '@schibsted-distribution/parcel-registration-api-client';
import { GlobalContext } from '../../globalContext';
import { useRouter } from 'next/router';
import { generateErrorText } from '../../lib/getApiErrorMessage';
import { SimpleNotification } from '../../components/SimpleNotification';
import { filters, TableFilter } from '../../components/Preset/TableFilter';
import { useGetWarehouses } from '../../hooks/externalRegisterApi';
import { ImportExportButtons } from './importExportButtons';
import { getDefaultPresetWorkstations } from '../../lib/getDefaultPresetWorkstations';

const allWorkstationsItem = 'All workstations';

export const Presets = () => {
  const { whoAmIData } = React.useContext(GlobalContext);
  const { data, isLoading, isError } = useGetPresets();
  const { mutate } = useDeletePreset();
  const { mutate: addPreset, isSuccess: isAddPresetSuccess, isError: isAddPresetError } = useAddPreset();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [selectedWorkstation, setSelectedWorkstation] = React.useState<string>(allWorkstationsItem);
  const [filter, setFilter] = React.useState<string | undefined>(filters.SHOW_ALL);
  const { data: warehousesData } = useGetWarehouses();
  const router = useRouter();

  const [checkboxSelection, setCheckboxSelection] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

  const deletePresetAction = async (id: GridRowId | string | null) => {
    if (!id) {
      return;
    }
    await mutate(id.toString());
  };

  const getAvaliableWorkstations = () => {
    const avaliableWarehouses = warehousesData
      ? warehousesData.filter(warehouse => whoAmIData.user && whoAmIData.user.warehouses.includes(warehouse.name))
      : [];
    const avaliableWorkstations: string[] = [];
    avaliableWarehouses.forEach(warehouse => {
      warehouse.workstations.forEach(workstation => {
        avaliableWorkstations.push(workstation.name);
      });
    });
    avaliableWorkstations.unshift(allWorkstationsItem);
    return avaliableWorkstations;
  };

  const [presetToDelete, setPresetToDelete] = React.useState<GridRowId | null>(null);

  const openConfirmationAlert = (id: GridRowId) => setPresetToDelete(id);

  const closeConfirmationAlert = () => setPresetToDelete(null);

  const workstations = getAvaliableWorkstations();

  const iconSize = {
    width: '20px',
    height: '20px'
  };

  const copyPreset = (data: PresetOutputWithRelations, warehousesData: Warehouse[]) => {
    const { id, created, updated, ...preset } = data;
    const newPreset: AddPresetParams = {
      ...preset,
      name: `clone - ${preset.name} - ${Date.now()}`,
      code: `clone - ${preset.code} - ${Date.now()}`,
      isParent: false,
      senderAddress: preset.senderAddress || null,
      receiverAddress: preset.receiverAddress || null,
      workStationsNames: getDefaultPresetWorkstations(whoAmIData, warehousesData, preset.workStations),
      labelHeader: preset.labelHeader || ''
    };
    addPreset(newPreset, {
      onSuccess: data => router.push('/presets/' + data?.id),
      onError: (error: any) => setErrorMessage(generateErrorText(error?.response))
    });
  };

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button onClick={() => setCheckboxSelection(!checkboxSelection)}>Toggle checkbox selection</Button>
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hide: true, disableColumnMenu: true },
    {
      field: 'isParent',
      align: 'center',
      flex: 0.25,
      headerName: '',
      sortable: false,
      filterable: false,
      renderCell: params => params.row.isParent && <LocalPolice color='success' />
    },
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'code', headerName: 'Code', flex: 2 },
    { field: 'alias', headerName: 'Alias', flex: 2 },
    {
      field: 'created',
      headerName: 'Created',
      flex: 1,
      renderCell: params => format(new Date(params.row.created), 'yyyy-MM-dd HH:mm')
    },
    {
      field: 'updated',
      headerName: 'Updated',
      flex: 1,
      renderCell: params => format(new Date(params.row.updated), 'yyyy-MM-dd HH:mm')
    },
    {
      field: 'actions',
      align: 'left',
      headerName: '',
      flex: 1.5,
      sortable: false,
      filterable: false,
      renderCell: params => {
        return (
          <div>
            {warehousesData && (
              <IconButton
                aria-label='Copy'
                color='secondary'
                onClick={() => copyPreset(params.row, warehousesData)}
                size='small'
              >
                <ContentCopy sx={iconSize} />
              </IconButton>
            )}
            <NextLink href={{ pathname: '/qr-wizard', query: { id: params.id } }} passHref>
              <IconButton aria-label='Create QR code' color='default' size='small'>
                <QrCode2 sx={iconSize} />
              </IconButton>
            </NextLink>
            {(whoAmIData.role === UserRole.ADMIN || !params.row.isParent) && (
              <NextLink href={'/presets/' + params.id} passHref>
                <IconButton aria-label='Edit' color='primary' size='small'>
                  <Edit sx={iconSize} />
                </IconButton>
              </NextLink>
            )}
            {(whoAmIData.role === UserRole.ADMIN || !params.row.isParent) && (
              <IconButton aria-label='Delete' color='error' onClick={() => openConfirmationAlert(params.id)} size='small'>
                <Delete sx={iconSize} />
              </IconButton>
            )}
          </div>
        );
      }
    }
  ];

  const filteredData = () => {
    if (!data) return [];
    if (filter === filters.SHOW_ALL) {
      if (whoAmIData.role === UserRole.LOCAL_ADMIN) {
        return data.filter(preset => preset.workStations.some(r => workstations.indexOf(r) >= 0) || preset?.isParent);
      }
      return data;
    } else if (filter === filters.HEAD_ADMINS_ONLY) {
      return data.filter(preset => preset.isParent);
    } else if (filter === filters.WORKSTATION_ONLY) {
      if (selectedWorkstation === 'All workstations') {
        return data.filter(preset => preset.workStations.some(r => workstations.indexOf(r) >= 0));
      }
      return data.filter(preset => preset?.workStations.find(r => r == selectedWorkstation));
    } else return [];
  };

  return (
    <MainLayout>
      <Grid container spacing={24} justifyContent='space-between' alignItems='center'>
        <Grid item>
          <h4>Presets:</h4>
        </Grid>
        <Grid item>
          {data && <ImportExportButtons data={data} selectionModel={selectionModel} />}
          {whoAmIData.role === UserRole.ADMIN && (
            <NextLink href='/presets/new' passHref>
              <Button sx={{ ml: 2 }} variant='outlined' endIcon={<Add />}>
                NEW
              </Button>
            </NextLink>
          )}
        </Grid>
      </Grid>
      <TableFilter
        filter={filter}
        setFilter={setFilter}
        selectedWorkstation={selectedWorkstation}
        setSelectedWorkstation={setSelectedWorkstation}
        role={whoAmIData.role}
        workstations={workstations}
      />
      <div style={{ height: '100%', width: '100%' }}>
        {isLoading && <div>loading...</div>}
        {isError && <div>Ups :(</div>}
        {data && (
          <>
            <NonSelectableGrid
              autoHeight
              loading={isLoading}
              rows={data ? filteredData() : []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 50, 100]}
              getRowClassName={params => `${params.row.isParent ? 'is-parent' : ''}`}
              disableSelectionOnClick
              checkboxSelection={checkboxSelection}
              onSelectionModelChange={newSelectionModel => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              isRowSelectable={params => params.row.isParent === false}
              components={{
                Toolbar: customToolbar
              }}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: 'updated',
                      sort: 'desc'
                    }
                  ]
                }
              }}
            />
            <p style={{ display: 'flex', alignItems: 'flex-end', fontSize: '14px' }}>
              <LocalPolice color='success' style={{ marginRight: '10px' }} /> Preset created by Head Admin.
            </p>
          </>
        )}
      </div>
      {!!presetToDelete && (
        <DeletePresetDialog
          open={true}
          handleClose={closeConfirmationAlert}
          handleConfirm={async () => deletePresetAction(presetToDelete)}
        />
      )}
      <SimpleNotification isError={isAddPresetError} isSuccess={isAddPresetSuccess} errorMessage={errorMessage} />
    </MainLayout>
  );
};
