import { UserRole, Warehouse, WorkStation } from '@schibsted-distribution/parcel-registration-api-client';
import { whoAmIDataType } from '../globalContext';

export function getDefaultPresetWorkstations(
  whoAmIData: whoAmIDataType,
  warehouses: Warehouse[],
  presetWorkstations: string[]
) {
  const userWorkstations: WorkStation[] = [];
  if (whoAmIData.user && warehouses) {
    if (whoAmIData.user.role === UserRole.ADMIN) {
      warehouses.map(warehouse => {
        userWorkstations.push(...warehouse.workstations);
      });
    } else {
      whoAmIData.user.warehouses.map(userWarehouse => {
        const warehouseDetails = warehouses.find(warehouse => warehouse.name === userWarehouse);
        if (warehouseDetails) userWorkstations.push(...warehouseDetails.workstations);
      });
    }
  }
  const userWorkstationsNames: string[] = userWorkstations.map(workstation => workstation.name);

  if (userWorkstationsNames.some(name => presetWorkstations.includes(name))) {
    const commonUserAndPresetWorkstations = presetWorkstations.filter(name => userWorkstationsNames.includes(name));
    return commonUserAndPresetWorkstations;
  } else {
    return userWorkstationsNames;
  }
}
