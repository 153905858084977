import { FormControl, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { UserRole } from '@schibsted-distribution/parcel-registration-api-client';

type TableFilterProps = {
  filter?: string;
  role?: string;
  setFilter: (value: string) => void;
  workstations: string[];
  selectedWorkstation?: string;
  setSelectedWorkstation: (value: string) => void;
};

export const filters: { [index: string]: string } = {
  SHOW_ALL: 'Show all',
  HEAD_ADMINS_ONLY: 'Show only presets of head admins',
  WORKSTATION_ONLY: 'Show only presets from my warehouses'
};

export const TableFilter = ({
  filter,
  role,
  setFilter,
  workstations,
  selectedWorkstation,
  setSelectedWorkstation
}: TableFilterProps) => {
  if (!role || role === UserRole.OPERATOR) return null;
  const filtersPerRole =
    role === UserRole.ADMIN ? (({ SHOW_ALL, HEAD_ADMINS_ONLY }) => ({ SHOW_ALL, HEAD_ADMINS_ONLY }))(filters) : filters;

  return (
    <>
      <FormControl sx={{ marginBottom: 2 }}>
        <Select id='filter-presets' value={filter} onChange={e => setFilter(e.target.value)}>
          {Object.keys(filtersPerRole).map(key => (
            <MenuItem key={key} value={filters[key]}>
              {filters[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ marginBottom: 2, marginLeft: 2 }}>
        {filter === filters.WORKSTATION_ONLY && (
          <Select
            id='filter-presets-workstation'
            value={selectedWorkstation}
            onChange={e => setSelectedWorkstation(e.target.value)}
          >
            {workstations.map(key => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </>
  );
};
