type ValidationError = {
  target: Object;
  property: string;
  value: any;
  constraints?: {
    [type: string]: string;
  };
  children?: ValidationError[];
};

type Response = {
  data: {
    errors?: ValidationError[];
  };
  status: Number;
  statusText: String;
};

export const generateErrorText = (response: Response) => {
  let errorMessage = '';
  errorMessage += `${response.status} - ${response.statusText}. `;

  if (response.data.errors) {
    errorMessage += response.data.errors
      .filter(error => error.constraints)
      .map(error => (error.constraints ? Object.values(error.constraints).join(',') : ''))
      .join(',');
  }

  return errorMessage;
};
