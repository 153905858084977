import { DataGrid } from '@mui/x-data-grid';
import styled from '@emotion/styled';
import React from 'react';

type NonSelectableGridProps = React.ComponentProps<typeof DataGrid>;

export const NonSelectableGrid = (props: NonSelectableGridProps) => {
  return <CustomGrid {...props} disableSelectionOnClick={true} />;
};

const CustomGrid = styled(DataGrid)`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: unset;
  }

  .MuiDataGrid-viewport,
  .MuiDataGrid-row,
  .MuiDataGrid-renderingZone {
    max-height: fit-content !important;
  }

  .MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .MuiDataGrid-virtualScrollerContent {
    height: auto !important;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    position: unset;
    height: auto;
  }

  .MuiDataGrid-main {
    min-height: 150px;
  }

  .MuiDataGrid-row.is-parent {
    background-color: #eaf9ffa3;
  }
`;
