import * as React from 'react';
import { AppBar, Button, Dialog, DialogActions, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

interface ConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => Promise<void>;
}

export const DeletePresetDialog = ({ open, handleClose, handleConfirm }: ConfirmationDialogProps) => {
  const [loading, setLoading] = React.useState(false);

  const confirmDelete = async () => {
    setLoading(true);
    try {
      await handleConfirm();
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Dialog open={open}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ width: '250px' }}>
          <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Delete Preset
          </Typography>
        </Toolbar>
      </AppBar>
      <div
        style={{
          margin: '30px 20px',
          width: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ margin: '20px', width: '350px' }}>
          <p>Are you sure you want to delete this Preset?</p>
        </div>
      </div>
      <DialogActions>
        <Button autoFocus color='info' variant='contained' disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton onClick={async () => confirmDelete()} loading={loading} variant='contained' color='error'>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
